//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionComponent from '@/components/Section.vue';
import { DEFAULT_PAGE_SETTING } from '@/enum';

export default {
  components: { SectionComponent },

  props: { settingOptions: { type: Object, default: () => ({}) } },

  data() {
    return {
      templateOptions: [
        {
          title: this.$t('News(Card list)'),
          key: 'card_list',
          img: { url: 'page-template-0.png', alt: 'card list' }
        },
        {
          title: this.$t('News(Card view)'),
          key: 'card_view',
          img: { url: 'page-template-1.png', alt: 'card view' }
        },
        {
          title: this.$t('News(List view)'),
          key: 'list_view',
          img: { url: 'page-template-2.png', alt: 'list view' }
        },
        {
          title: this.$t('News(Category)'),
          key: 'group_view',
          img: { url: 'page-template-3.png', alt: 'group view' }
        }
      ],
      categoryHierarchyOptions: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
      ],
      expanded: this.$ls.get('uv-exp-page-template-section') ?? false
    };
  },

  computed: {
    pageTemplate() {
      return this.settingOptions?.page_template || 'card_view';
    },
    categoryLevel() {
      return this.settingOptions?.category_level || '1';
    },

    // Column setting
    columnSetting() {
      return this.settingOptions?.column_setting || DEFAULT_PAGE_SETTING?.column_setting;
    },
    alignCategoryItem() {
      return this.settingOptions?.align_category_item || 'hor-ver'
    }
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-page-template-section', this.expanded);
    },
    changePageTemplate(key) {
      const columnNumber = {
        card_view: 6,
        card_list: this.columnSetting?.number,
        group_view: this.alignCategoryItem === 'hor-ver' ? 1 : 4,
        list_view: this.columnSetting?.number,
      }[key]
      const column_setting = {
        ...this.columnSetting,
        number: columnNumber
      }
      this.updateSettingOptions({ page_template: key, column_setting });
    },
    changeCategoryLevel(value) {
      this.updateSettingOptions({ category_level: value });
    },
    updateSettingOptions(value) {
      this.$emit('update-setting-options', value);
    }
  }
};
