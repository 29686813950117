var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        id: "site-editor",
        width: "95%",
        placement: "right",
        headerStyle: {
          position: "absolute",
          width: "100%",
          zIndex: 2,
          padding: "8px 24px"
        },
        "body-style": { background: "#eff3f8", marginTop: "62px" },
        visible: _vm.visible,
        closable: false
      },
      on: { close: _vm.handleCancel }
    },
    [
      _c(
        "div",
        {
          staticClass: "flex content-between",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("div", { staticClass: "font-semi-bold h6" }, [
            _vm._v(" " + _vm._s(_vm.$t("Website editor")) + " ")
          ]),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "a-button",
                {
                  attrs: { icon: "appstore", type: "dashed", size: "large" },
                  on: { click: _vm.handleRedirectToCodeList }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Code list")) + " ")]
              ),
              _c("a-divider", {
                staticStyle: { "font-size": "50px" },
                attrs: { type: "vertical" }
              }),
              _c(
                "a-popconfirm",
                {
                  staticClass: "mr-xs",
                  attrs: {
                    placement: "bottom",
                    title: _vm.$t("Are you sure to discard changes?"),
                    "ok-text": _vm.$t("Yes"),
                    "cancel-text": _vm.$t("No"),
                    disabled: !_vm.isDraftEdited || _vm.loading
                  },
                  on: { confirm: _vm.handleDiscardDraft }
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        size: "large",
                        icon: "rollback",
                        disabled: !_vm.isDraftEdited || _vm.loading
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Discard changes")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "a-button",
                {
                  staticClass: "mr-xs",
                  attrs: { size: "large", icon: "history" },
                  on: { click: _vm.handleOpenRevisionHistory }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Revision history")) + " ")]
              ),
              _c(
                "a-button",
                {
                  staticClass: "mr-xs",
                  class: { blink: true, blinking: !_vm.disabledPublishButton },
                  attrs: {
                    size: "large",
                    type: "primary",
                    disabled: _vm.disabledPublishButton,
                    loading: _vm.loading,
                    icon: "to-top"
                  },
                  on: {
                    click: function($event) {
                      _vm.publish.visible = true
                    }
                  }
                },
                [
                  _vm.page.ispublic
                    ? _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("Save and Update")) + " ")
                      ])
                    : _c("span", [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")])
                ]
              ),
              _c("a-button", {
                attrs: { size: "large", icon: "close" },
                on: { click: _vm.handleCancel }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c("alert", {
            staticClass: "page__text-small",
            attrs: { page: _vm.page }
          }),
          _c("br"),
          _c("publish-info", {
            staticClass: "page__text-small",
            attrs: {
              page: _vm.newPage,
              "iframe-code": _vm.iframeCode,
              "publish-url": _vm.publishUrl,
              "base-url": _vm.baseUrl,
              slug: _vm.pageSlug,
              "is-primary": _vm.isPrimaryState
            },
            on: {
              "update-slug": _vm.updateSlug,
              "change-iframe-code": _vm.changeIframeCode
            }
          }),
          _c("br"),
          _c("page-template", {
            staticClass: "page__text-small",
            attrs: { "setting-options": _vm.settingOptions },
            on: { "update-setting-options": _vm.updateSettingOptions }
          }),
          _c("br"),
          _c("page-option", {
            staticClass: "page__text-small",
            attrs: {
              "page-id": _vm.pageId,
              page: _vm.newPage,
              "setting-options": _vm.settingOptions
            },
            on: { "update-setting-options": _vm.updateSettingOptions }
          }),
          _c("br"),
          _c("item-display-option", {
            staticClass: "page__text-small",
            attrs: { "setting-options": _vm.settingOptions },
            on: { "update-setting-options": _vm.updateSettingOptions }
          }),
          _c("br"),
          _c("modal-tts-option", {
            staticClass: "page__text-small",
            attrs: { "setting-options": _vm.settingOptions },
            on: { "update-setting-options": _vm.updateSettingOptions }
          }),
          _c("br"),
          _c("search-option", {
            staticClass: "page__text-small",
            attrs: { "setting-options": _vm.settingOptions },
            on: { "update-setting-options": _vm.updateSettingOptions }
          }),
          _c("br"),
          _c("preview", {
            staticClass: "page__text-small",
            attrs: {
              page: _vm.newPage,
              "setting-options": _vm.settingOptions,
              "iframe-code": _vm.iframeCode,
              "draft-iframe-code": _vm.draftIframeCode
            },
            on: {
              "change-iframe-code": _vm.changeIframeCode,
              "change-draft-iframe-code": _vm.changeDraftIframeCode,
              cancel: _vm.handleCancel,
              "update-setting-options": _vm.updateSettingOptions
            }
          })
        ],
        1
      ),
      _c("PublishWebsiteModal", {
        attrs: {
          visible: _vm.publish.visible,
          loading: _vm.publish.loading,
          page: _vm.newPage
        },
        on: {
          publish: _vm.publishPageSettingOptions,
          close: function($event) {
            _vm.publish.visible = false
          }
        }
      }),
      _c("RevisionHistoryModal", {
        attrs: {
          visible: _vm.revision.visible,
          loading: _vm.revision.loading,
          items: _vm.revision.items,
          page: _vm.newPage
        },
        on: {
          revert: _vm.handleRevertSettingOptions,
          preview: _vm.previewPageSettingOptions,
          close: function($event) {
            _vm.revision.visible = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }